// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminMoviePoster_adminMoviePoster__r6ORD {\n  margin-top: 100px;\n}\n\n.AdminMoviePoster_adminMoviePostItem__k2uya {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.AdminMoviePoster_movieListDate__wcj5U {\n  overflow-x: scroll;\n  padding: 0 10px;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n}\n\n.AdminMoviePoster_movieListDateButton__u-veP {\n  background-color: #642d6b;\n  border: 2px solid #642d6b;\n}\n\n.AdminMoviePoster_movieListDateButton__u-veP:hover {\n  background-color: #fff;\n  border: 2px solid #642d6b;\n  color: #642d6b;\n}\n\n@media screen and (max-width: 600px) {\n  .AdminMoviePoster_movieListDateButton__u-veP {\n    font-size: 12px;\n    padding: 5px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Admin/AdminMoviePoster/AdminMoviePoster.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".adminMoviePoster {\n  margin-top: 100px;\n}\n\n.adminMoviePostItem {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.movieListDate {\n  overflow-x: scroll;\n  padding: 0 10px;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 10px;\n}\n\n.movieListDateButton {\n  background-color: #642d6b;\n  border: 2px solid #642d6b;\n}\n\n.movieListDateButton:hover {\n  background-color: #fff;\n  border: 2px solid #642d6b;\n  color: #642d6b;\n}\n\n@media screen and (max-width: 600px) {\n  .movieListDateButton {\n    font-size: 12px;\n    padding: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminMoviePoster": "AdminMoviePoster_adminMoviePoster__r6ORD",
	"adminMoviePostItem": "AdminMoviePoster_adminMoviePostItem__k2uya",
	"movieListDate": "AdminMoviePoster_movieListDate__wcj5U",
	"movieListDateButton": "AdminMoviePoster_movieListDateButton__u-veP"
};
export default ___CSS_LOADER_EXPORT___;
