// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminMovieList_adminMovieList__6lRmq {\n  margin-top: 100px;\n}\n\n.AdminMovieList_adminMovieListItem__65o\\+4 {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.AdminMovieList_adminMovieListSearchBar__nOZTS {\n  display: flex;\n  justify-content: center;\n}\n.AdminMovieList_adminMovieListSearch__kPXqm {\n  width: 80vw;\n  padding: 10px;\n  margin: 10px 20px;\n  border-radius: 5px;\n}\n\n@media screen and (max-width: 425px) {\n  .AdminMovieList_adminMovieListSearchBar__nOZTS {\n    justify-content: flex-start;\n  }\n\n  .AdminMovieList_adminMovieListSearch__kPXqm {\n    width: 60vw;\n    margin: 10px 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Admin/AdminMovieList/AdminMovieList.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,2BAA2B;EAC7B;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;AACF","sourcesContent":[".adminMovieList {\n  margin-top: 100px;\n}\n\n.adminMovieListItem {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.adminMovieListSearchBar {\n  display: flex;\n  justify-content: center;\n}\n.adminMovieListSearch {\n  width: 80vw;\n  padding: 10px;\n  margin: 10px 20px;\n  border-radius: 5px;\n}\n\n@media screen and (max-width: 425px) {\n  .adminMovieListSearchBar {\n    justify-content: flex-start;\n  }\n\n  .adminMovieListSearch {\n    width: 60vw;\n    margin: 10px 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminMovieList": "AdminMovieList_adminMovieList__6lRmq",
	"adminMovieListItem": "AdminMovieList_adminMovieListItem__65o+4",
	"adminMovieListSearchBar": "AdminMovieList_adminMovieListSearchBar__nOZTS",
	"adminMovieListSearch": "AdminMovieList_adminMovieListSearch__kPXqm"
};
export default ___CSS_LOADER_EXPORT___;
